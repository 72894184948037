(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('PolicyDefinitionEditDialogController', PolicyDefinitionEditDialogController);

    PolicyDefinitionEditDialogController.$inject = ['Notification', '$uibModalInstance', '$http', '$translate', '$scope', 'PolicyDefinition', '$uibModal', 'records', 'policyImplementations', 'policyTypes', 'policyType', 'newPolicy', '$window', '$q'];

    function PolicyDefinitionEditDialogController(Notification, $uibModalInstance, $http, $translate, $scope, PolicyDefinition, $uibModal, records, policyImplementations, policyTypes, policyType, newPolicy, $window, $q) {

        /***********************************************************************/
        /*** COMPONENTE PARA EDITAR/APAGAR MULTIPLAS HIERARQUIAS EM BULK *******/
        /*** VALIDO PARA HIERARQUIAS QUE PARTILHEM O MESMO METODO DE CALCULO ***/
        /***********************************************************************/

        var vm = this;
        $scope.policyDefinition = null;
        $scope.records = records;
        $scope.policyImplementations = policyImplementations;
        $scope.policyTypes = policyTypes;
        $scope.policyType = policyType;
        $scope.newPolicy = newPolicy;
        $scope.multiplePolicyDefinition = false;
        $scope.saveState = 0;
        $scope.hierarchySelections = [];
        $scope.hierarchySelectionIds = [];

        vm.doInit = doInit;
        vm.clear = clear;
        vm.nextSaveState = nextSaveState;
        vm.previousSaveState = previousSaveState;
        vm.isValidToDelete = isValidToDelete;
        vm.isImplDefined = isImplDefined;
        vm.deletePolicies = deletePolicies;
        vm.savePolicies = savePolicies;
        vm.getTemplate = getTemplate;
        vm.createNewPolicyDefinition = createNewPolicyDefinition;
        vm.initializePolicyDefinition = initializePolicyDefinition;
        vm.translateString = translateString;
        vm.addToSelectList = addToSelectList;
        var loadingItem = {type: 'loading'}, hasNextChunk = true;

        doInit();

        function doInit() {
            if ($scope.newPolicy === true) {
                $scope.policyDefinition = createNewPolicyDefinition();
                $scope.records = [$scope.policyDefinition];
                $scope.saveState = 1;
            } else if ($scope.records) {
                $scope.multiplePolicyDefinition = $scope.records.length > 1;
                if ($scope.records.length > 0) {
                    angular.forEach($scope.records, function (policyDefinition) {
                        initializePolicyDefinition(policyDefinition);
                    });
                    $scope.policyDefinition = $scope.records[0];
                }
            }
        }

        function createNewPolicyDefinition() {
            var policyDefinition = new PolicyDefinition();
            policyDefinition.policyType = $scope.policyType.fqn;
            $http.get("api/policy-builder/hierarchy/" + policyDefinition.policyType).then(function (result) {
                if (policyDefinition.hierarchy == null) {
                    policyDefinition.hierarchy = result.data;
                }
                angular.forEach(result.data.metadata, function (k) {
                    if (k.type === 'select' || k.type === 'enum') {
                        $http.get("api/" + k.url + "/").then(function (result) {
                            policyDefinition.hierarchy[k.name + "_list"] = result.data;
                        });
                    }
                });
            });
            return policyDefinition;
        }

        function initializePolicyDefinition(policyDefinition) {
            if (policyDefinition.hierarchy && policyDefinition.hierarchy.metadata) {
                angular.forEach(policyDefinition.hierarchy.metadata, function (value) {
                    if (value.type === 'select' || value.type === 'enum') {
                        $http.get("api/" + value.url + "/").then(function (result) {
                            policyDefinition.hierarchy[value.name + "_list"] = result.data;
                            if (policyDefinition.hierarchy[value.name]) {
                                policyDefinition.hierarchy[value.name] = eval("(" + policyDefinition.hierarchy[value.name] + ")");
                            }
                        });
                    }
                    if (value.type === 'searchSelect') {
                        if (policyDefinition.hierarchy[value.name]) {
                            policyDefinition.hierarchy[value.name] = eval("(" + policyDefinition.hierarchy[value.name] + ")");
                        }
                    }
                });
            }

            if (policyDefinition.input && policyDefinition.input.metadata) {
                angular.forEach(policyDefinition.input.metadata, function (value) {
                    if (value.type === 'date') {
                        if (policyDefinition.input[value.name] != null) {
                            policyDefinition.input[value.name] = moment(policyDefinition.input[value.name]).format('YYYY-MM-DD HH:mm');
                        }
                    }
                    if (value.type === 'select' || value.type === 'listSelect') {
                        $http.get("api/" + value.url + "/").then(function (result) {
                            policyDefinition.input[value.name + "_list"] = result.data;
                            if (policyDefinition.input[value.name]) {
                                policyDefinition.input[value.name] = eval("(" + policyDefinition.input[value.name] + ")");
                            }
                        });
                    }
                });
            }
        }

        function translateString(str) {
            return $translate.instant(str);
        }

        function nextSaveState() {
            $scope.saveState++;
        }

        function previousSaveState() {
            $scope.saveState--;
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function isValidToDelete() {
            return $scope.records && $scope.records[0].id;
        }

        function isImplDefined() {
            return $scope.policyDefinition != null && $scope.policyDefinition.policyImplementation != null;
        }

        function deletePolicies() {
            $uibModal.open({
                templateUrl: 'app/entities/policy-definition/policy-definition-delete-dialog.html',
                controller: 'PolicyDefinitionDeleteDialogController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    records: function () {
                        return $scope.records;
                    }
                }
            }).result.then(function (res) {
                $uibModalInstance.close(res);
            }, function () {
            });
        }

        function savePolicies() {
            angular.forEach($scope.records, function (policyDefinition) {
                if (policyDefinition.hierarchy.clientType) {
                    policyDefinition.hierarchy.clientType = JSON.stringify(policyDefinition.hierarchy.clientType);
                }
                if (policyDefinition.hierarchy.clientGroup) {
                    policyDefinition.hierarchy.clientGroup = JSON.stringify(policyDefinition.hierarchy.clientGroup);
                }
                if (policyDefinition.hierarchy.client) {
                    policyDefinition.hierarchy.client = JSON.stringify(policyDefinition.hierarchy.client);
                }
                if (policyDefinition.hierarchy.thirdParty) {
                    policyDefinition.hierarchy.thirdParty = JSON.stringify(policyDefinition.hierarchy.thirdParty);
                }
                if (policyDefinition.hierarchy.laboratory) {
                    policyDefinition.hierarchy.laboratory = JSON.stringify(policyDefinition.hierarchy.laboratory);
                }
                if (policyDefinition.hierarchy.product) {
                    policyDefinition.hierarchy.product = JSON.stringify(policyDefinition.hierarchy.product);
                }
                if (policyDefinition.hierarchy.customer) {
                    policyDefinition.hierarchy.customer = JSON.stringify(policyDefinition.hierarchy.customer);
                }
                if (policyDefinition.hierarchy.supplier) {
                    policyDefinition.hierarchy.supplier = JSON.stringify(policyDefinition.hierarchy.supplier);
                }
                if (policyDefinition.hierarchy.companyType) {
                    policyDefinition.hierarchy.companyType = JSON.stringify(policyDefinition.hierarchy.companyType);
                }
                if (policyDefinition.hierarchy.productList) {
                    policyDefinition.hierarchy.productList = JSON.stringify(policyDefinition.hierarchy.productList);
                }
                if (policyDefinition.hierarchy.modelList) {
                    policyDefinition.hierarchy.modelList = JSON.stringify(policyDefinition.hierarchy.modelList);
                }
                if (policyDefinition.hierarchy.username) {
                    policyDefinition.hierarchy.username = JSON.stringify(policyDefinition.hierarchy.username);
                }
                /* ATENÇAO NAO CORRER  JSON.stringify SOBRE HIERARQUIAS DO TIPO STRING, EX: A STRING "TRADE" VAI SER TRANSFORMADA EM ""TRADE"" E ISSO ESTRAGA O SQL QUANDO SE FOR A PROCURA DA POLITICA */
            });

            if ($scope.policyDefinition != null && $scope.policyDefinition.policyImplementation != null) {
                if ($scope.policyDefinition.input) {
                    if ($scope.policyDefinition.input.priceList) {
                        $scope.policyDefinition.input.priceList = JSON.stringify($scope.policyDefinition.input.priceList);
                    }
                    if ($scope.policyDefinition.input.warehouse) {
                        $scope.policyDefinition.input.warehouse = JSON.stringify($scope.policyDefinition.input.warehouse);
                    }
                    if ($scope.policyDefinition.input.logisticValueBasePriceList) {
                        $scope.policyDefinition.input.logisticValueBasePriceList = JSON.stringify($scope.policyDefinition.input.logisticValueBasePriceList);
                    }

                    var tmp_id;
                    angular.forEach($scope.records, function (policyDefinition) {
                        tmp_id = policyDefinition.input.id;
                        policyDefinition.input = angular.copy($scope.policyDefinition.input);
                        policyDefinition.input.id = tmp_id;
                    });

                    if ($scope.policyDefinition.id != null) {
                        PolicyDefinition.updateAll($scope.records, onSaveFinished, onSaveError);
                    } else {
                        PolicyDefinition.save($scope.policyDefinition, onSaveFinished, onSaveError);
                    }
                } else {
                    Notification.error({message: "Erro a guardar parâmetros do método de cálculo", delay: 5000});
                }
            } else {
                Notification.error({message: "Não indicou um método de cálculo válido", delay: 5000});
            }
        }

        var onSaveError = function (response) {
            if (response.status === 406) {
                Notification.error({
                    message: $translate.instant('pharmaPortalApp.policyDefinition.policyHierarchy.hash.notUnique'),
                    delay: 5000
                });
            } else {
                Notification.error({message: "Erro ao gravar política(s). Verifique parametrizações", delay: 5000});
            }
        }

        var onSaveFinished = function () {
            Notification.success({message: "Política(s) guardada(s) com sucesso", delay: 5000});
            clearHierarchySelections();
            if (!$scope.newPolicy) {
                $uibModalInstance.close(true);
            }
        };

        function getTemplate(templatePath) {
            $window.open(templatePath);
        }

        function addLoadingStateItem() {
            $scope.typeaheadArray.push(loadingItem);
        }

        function removeLoadingStateItem() {
            var index = $scope.typeaheadArray.indexOf(loadingItem);
            if (index < 0) {
                return;
            }
            $scope.typeaheadArray.splice(index, 1);
        }

        /******************************* SCOPED ***************************************/
        $scope.requestMoreItems = function (field) {
            if ($scope.isRequestMoreItems || !hasNextChunk) {
                $scope.isRequestMoreItems = false;
                return $q.reject();
            }

            addLoadingStateItem();

            $scope.isRequestMoreItems = true;
            return $scope.loadDataForTypeahead($scope.lastPage + 1, $scope.lastNeedle, field.url)
                .then(function (result) {
                    //se ja existir é porque nao há mais
                    if (result.data.length === 0) {
                        hasNextChunk = false;
                        return $q.reject();
                    } else {
                        $scope.typeaheadArray = $scope.typeaheadArray.concat(result.data);
                    }
                    return result;
                }, function (err) {
                    return $q.reject(err);
                })
                .finally(function () {
                    removeLoadingStateItem();
                    $scope.isRequestMoreItems = false;
                });
        };

        $scope.refreshList = function (field, query) {
            if ($scope.lastNeedle === query) {
                return;
            }
            hasNextChunk = true;
            $scope.loadDataForTypeahead(0, query, field.url).then(function (result) {
                $scope.typeaheadArray = result.data;
            });
        };

        $scope.loadDataForTypeahead = function (page, needle, url) {
            $scope.lastNeedle = needle;
            $scope.lastPage = page;
            return $http.get("api/" + url + "/", {params: {query: needle, page: page, size: 30}});
        }

        $scope.refreshSelectModel = function (query, url, fieldName) {
            $http.get("api/" + url + "/" + query).then(function (result) {
                if (result.data.length > 500) {
                    Notification.success({
                        message: "O número de resultados é superior a 500 registos. Por questões de performance, refine a sua pesquisa",
                        delay: 5000
                    });
                } else {
                    $scope.policyDefinition.hierarchy[fieldName + '_list'] = result.data;
                }
            });
        };

        $scope.updateInputs = function () {
            if ($scope.policyDefinition.policyImplementation != null) {
                $http.get("api/policy-builder/input/" + $scope.policyDefinition.policyImplementation).then(function (result) {
                    $scope.policyDefinition.input = result.data;
                }).finally(function () {
                    $scope.refreshInputs();
                });
            } else {
                $scope.policyDefinition.input = {metadata: []};
                $scope.refreshInputs();
            }
        }

        $scope.refreshInputs = function () {
            angular.forEach($scope.policyDefinition.input.metadata, function (value) {
                if (value.type === 'date') {
                    if ($scope.policyDefinition.input[value.name] != null) {
                        $scope.policyDefinition.input[value.name] = moment($scope.policyDefinition.input[value.name]).format('YYYY-MM-DD HH:mm');
                    }
                }
                if (value.type === 'select' || value.type === 'listSelect') {
                    $http.get("api/" + value.url + "/").then(function (result) {
                        $scope.policyDefinition.input[value.name + "_list"] = result.data;
                    });
                }
            });
        }

        $scope.addNew = function (fieldName) {
            if (!angular.isObject($scope.policyDefinition.input[fieldName])) {
                $scope.policyDefinition.input[fieldName] = [];
            }
            $scope.policyDefinition.input[fieldName].push('');
        }

        $scope.addNew12 = function (fieldName) {
            if (!angular.isObject($scope.policyDefinition.input[fieldName])) {
                $scope.policyDefinition.input[fieldName] = [];
            }
            var i;
            for (i = 1; i <= 12; i++) {
                $scope.policyDefinition.input[fieldName].push('');
            }
        }

        function addToSelectList(selectInput) {
            if ($scope.hierarchySelectionIds.indexOf(selectInput.generatedId) === -1) {
                $scope.hierarchySelections.push(selectInput);
                $scope.hierarchySelectionIds.push(selectInput.generatedId);
            }
        }

        function clearHierarchySelections() {
            $scope.hierarchySelectionIds = [];
            angular.forEach($scope.hierarchySelections, function (value) {
                value.selected = null;
            });
        }
    }
})();
